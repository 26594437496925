import React from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import {Link} from 'react-scroll'
import {SCROLL_TO_TOP_DURATION_MS} from './../constants/constants'

export default function ScrollToTop() {
  return (
    <StyledComponent>
      <Fade>
        <Link
          className="btn btn--primary  btn--scroll-to-top"
          to="top"
          smooth={true}
          offset={0}
          duration={SCROLL_TO_TOP_DURATION_MS}
        >
          <span className="sr-only">Scroll to top</span>
          <svg x="0" y="0" viewBox="0 0 500 292.4" fill="#000">
            <path d="M41 1l208 207L457 0l43 43-249 249L0 42z" />
          </svg>
        </Link>
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  margin: 8rem 0 0 0;

  @media (min-width: 1200px) {
    display: none;
  }

  .btn--scroll-to-top {
    background-color: transparent;
    border: 0;
    display: block;
    margin: 0 auto;
    opacity: 0.2;
    transform: scaleY(-1);
    width: 9.6rem;

    &:hover {
      opacity: 0.5;
    }
  }
`
